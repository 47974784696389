import React, { useState } from 'react';
import './App.css';
import Graph from './components/Graph';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { FallingEmojis } from 'falling-emojis';
import { Euro } from '@mui/icons-material';

const App = () => {
	const [euroRain, setEuroRain] = useState(true);

	return (
		<Box className="App" sx={{ bgcolor: 'primary.light' }} height="110vh">
			{euroRain ? <FallingEmojis emoji={'💶'} /> : ''}

			<Container maxWidth="md" sx={{ bgcolor: 'white' }} pb={2} pt={2}>
				<Box mt={0} pt={2}>
					<h1>Visualiseer mijn pensioen</h1>
				</Box>

				<Button
					variant="outlined"
					size="large"
					onClick={() => setEuroRain(!euroRain)}
				>
					<Euro color="success" />
					{!euroRain ? 'Laat het geld regenen!' : 'Stop deze geldregen!'}{' '}
					<Euro color="success" />
				</Button>

				<p>
					Veel mensen{' '}
					<a href="https://www.youtube.com/watch?v=3tcHzIcrdms" target="_blank">
						dromen van een vroeg pensioen
					</a>
					. Weinig mensen doen er echter daadwerkelijk wat aan om dit te bereiken.
					Met behulp van deze tool kun je inzicht krijgen wat het voor jou betekent
					als je elke maand geld extra opzij legt voor je pensioen.
				</p>

				<p>
					De zeer gewaardeerde sponsor van deze website leerde mij ooit dat het in
					het leven draait om <b>KDM</b> wanneer je echt iets wilt bereiken. KDM
					staat voor <b>karakter, discipline en motivatie</b>. Wanneer jij deze drie
					elementen beheerst en je <b>elke maand, jaar in jaar uit</b> aandelen
					koopt, dan is jouw pensioen dichter bij dan je ooit had durven dromen!
				</p>

				<p>
					Deze website kan jou helpen bij het motivatie element van KDM. Door dag in
					dag uit jouw pensioen te blijven visualiseren, zul je zien dat jouw
					motivatie toeneemt. Karakter en discipline zal uit jezelf moeten komen.
				</p>

				<h3> Jij kunt dit, gas erop!</h3>

				<br />

				<Graph />

				<p>Deze site is mede mogelijk gemaakt door trotse sponsor DdS</p>
			</Container>
		</Box>
	);
};

export default App;
