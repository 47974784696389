import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { RocketLaunch } from '@mui/icons-material';

let myLineChart;

const calculate_principal = ({ P, r, n, t }) => {
	return P * Math.pow(1 + r / n, t * n);
};

const calculate_contributions_compounded = ({ PMT, r, n, t }) => {
	return PMT * ((Math.pow(1 + r / n, t * n) - 1) / (r / n));
};

const calculate_compound_per_time = ({
	principal,
	monthlyContribution,
	interestRate,
	years,
}) => {
	let P = principal; // principal or initial value
	let PMT = monthlyContribution; // monthly contribution
	let r = interestRate; // annual interest rate
	let n = 12; // number of times the interest is compounded per year #magic_number
	let t = years; // the number of years

	const principalCompounded = calculate_principal({ P, r, n, t });

	const monthlyContributionsCompounded = calculate_contributions_compounded({
		PMT,
		r,
		n,
		t,
	});

	let total = principalCompounded + monthlyContributionsCompounded;

	return parseFloat(total.toFixed(2));
};

const yearly_compounds = ({
	years,
	principal,
	monthlyContribution,
	interestRate,
}) => {
	let year;
	let yearCompounds = [];

	for (year = 1; year <= years; year++) {
		const compoundPerTime = {
			principal,
			monthlyContribution,
			interestRate,
			years: year,
		};

		yearCompounds.push(calculate_compound_per_time(compoundPerTime));
	}

	return yearCompounds;
};

const get_years = (amountYears) => {
	let year = parseInt(moment().format('YYYY'));
	let years = [];
	let i;

	for (i = 1; i <= amountYears; i++) {
		years.push((year + i).toString());
	}

	return years;
};

const Graph = () => {
	const [years, setYears] = useState(30);
	const [principal, setPrincipal] = useState(0);
	const [monthlyContribution, setMonthlyContributions] = useState(250);
	const [annualInterestRate, setAnnualInterestRate] = useState(6);
	const [newData, setNewData] = useState(0);

	useEffect(() => {
		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};

		setNewData(yearly_compounds(options).slice(-1)[0] || 0);

		buildChart();
	}, [newData]);

	const buildChart = () => {
		let ctx = document.getElementById('myChart').getContext('2d');

		if (typeof myLineChart !== 'undefined') myLineChart.destroy();

		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};

		const data = yearly_compounds(options);
		const labels = get_years(options.years);

		myLineChart = new Chart(ctx, {
			type: 'line',
			data: {
				labels: labels,
				datasets: [
					{
						label: 'Euro totaal',
						data: data,
						fill: true,
						backgroundColor: ['rgba(54, 162, 235, 0.75)'],
						borderColor: ['rgba(54, 162, 235, 1)'],
						borderWidth: 1,
					},
				],
			},
		});
	};

	const handleYearsChange = (years) => {
		setYears(years);
		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};

		setNewData(yearly_compounds(options).slice(-1)[0] || 0);
	};

	const handlePrincipalChange = (principal) => {
		setPrincipal(principal);
		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};

		setNewData(yearly_compounds(options).slice(-1)[0] || 0);
	};

	const handleMonthlyContributionsChange = (monthlyContribution) => {
		setMonthlyContributions(monthlyContribution);
		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};

		setNewData(yearly_compounds(options).slice(-1)[0] || 0);
	};

	const handleAnnualInterestRateChange = (annualInterestRate) => {
		setAnnualInterestRate(annualInterestRate);
		const options = {
			years: years,
			principal: principal,
			monthlyContribution: monthlyContribution,
			interestRate: annualInterestRate / 100.0,
		};
		setNewData(yearly_compounds(options).slice(-1)[0] || 0);
	};

	return (
		<div>
			<Stack>
				<Grid container spacing={2}>
					<Grid item xs={6} md={3}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Tijdsduur investering"
							variant="outlined"
							type="number"
							defaultValue={years}
							onChange={(event) => {
								const value = event.target.value;
								if (value !== '') {
									handleYearsChange(parseInt(event.target.value));
								} else {
									handleYearsChange(0);
								}
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">jaar</InputAdornment>,
							}}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Initiële storting"
							variant="outlined"
							type="number"
							defaultValue={principal}
							onChange={(event) => {
								const value = event.target.value;
								if (value !== '') {
									handlePrincipalChange(parseInt(event.target.value));
								} else {
									handlePrincipalChange(0);
								}
							}}
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>,
							}}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Maandelijkse storting"
							variant="outlined"
							type="number"
							defaultValue={monthlyContribution}
							onChange={(event) => {
								const value = event.target.value;
								if (value !== '') {
									handleMonthlyContributionsChange(parseInt(event.target.value));
								} else {
									handleMonthlyContributionsChange(0);
								}
							}}
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>,
							}}
						/>
					</Grid>
					<Grid item xs={6} md={3}>
						<TextField
							fullWidth
							id="outlined-basic"
							label="Rendement"
							variant="outlined"
							type="number"
							defaultValue={annualInterestRate}
							onChange={(event) => {
								const value = event.target.value;
								if (value !== '') {
									handleAnnualInterestRateChange(parseInt(event.target.value));
								} else {
									handleAnnualInterestRateChange(0);
								}
							}}
							InputProps={{
								endAdornment: <InputAdornment position="end">%</InputAdornment>,
							}}
						></TextField>
					</Grid>
				</Grid>
				<h3>
					Resultaat <RocketLaunch color="primary" />:{' '}
					{newData?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}{' '}
					euro!
				</h3>
				<p>
					Wanneer je uitgaat van{' '}
					<a
						href="https://www.schwab.com/learn/story/beyond-4-rule-how-much-can-you-spend-retirement"
						target="_blank"
					>
						4%
					</a>{' '}
					als veilig uitbetaal percentage, betekent dit dat je over {years} jaar een{' '}
					<strong>passief inkomen</strong> hebt van{' '}
					<strong>
						{Math.floor(newData / 25 / 12)?.toLocaleString('nl-NL', {
							style: 'currency',
							currency: 'EUR',
						})}{' '}
						euro per maand
					</strong>
					.
				</p>
				<p>
					*Hou er rekening mee dat inflatie en vermogensbelasting in deze tool niet
					is meegenomen. Dit kun je naar eigen inzicht van het ingeschatte rendement
					aftrekken om hier rekening mee te houden. Daarnaast houdt deze tool ook
					geen rekening met AOW of ander opgebouwd vermogen zoals pensioen bij een
					werkgever.
				</p>
				<canvas id="myChart" width="400" height="150"></canvas>
			</Stack>
		</div>
	);
};

export default Graph;
